<template>
	<div>
		<div class="row no-gutters mt-3">
			<div class="col-12 pb-3 d-flex align-items-center">
				<b-button
					class="mr-2"
					variant="primary"
					@click="goBack">
					<i class="fa fa-arrow-left pr-2" />{{ translate('go_back') }}
				</b-button>
				<h5
					v-if="!loadingInitialInformation"
					class="m-0">
					<b>{{ `${translate('order_id')}: ${orderData.invoice_id}` }}</b>
				</h5>
			</div>
			<div class="col d-flex justify-content-center">
				<div
					class="h2">
					{{ translate('use_another_payment_method') }}
				</div>
			</div>
		</div>
		<b-alert
			v-if="hasValidationErrors"
			class="mt-2"
			show
			variant="danger">
			<h5 class="font-weight-bold">
				<i class="fa fa-exclamation-triangle" /> {{ translate('verify_following_errors') }}:
			</h5>
			<ul class="h6">
				<template v-for="(error, index) in validationErrors">
					<li :key="index">
						{{ error }}
					</li>
				</template>
			</ul>
		</b-alert>
		<div
			:class="['xs'].includes(windowWidth) ? 'mt-2' : 'mt-3'"
			class="row mb-5">
			<div class="col-12 col-md-8">
				<div class="row">
					<div class="col-12">
						<flow-info-group>
							<flow-info-section
								compact
								:title="`${translate('shipping_information')}`"
								hide-controls
								hide-cancel
								override-edit>
								<div>
									<p class="m-0">
										<i class="fas fa-shopping-bag mr-2" />
										<b>{{ translate('pickup_title') }}</b>
									</p>
									<p>
										{{ translate('pickup_description_1') }}
										<br>
										{{ translate('pickup_description_2') }}
									</p>
								</div>
							</flow-info-section>
							<flow-info-section
								init-edit
								hide-controls
								:title="`${translate('payment_method')}`"
								active-color="dark"
								hide-cancel
								override-edit
								no-inner-spacing>
								<payment-edit
									:clicked-payment-method="clickedPayment"
									:loading-order-data="loadingInitialInformation"
									:loading-change-payment-method="loadingChangePaymentMethod"
									:change-payment-method-errors="changePaymentMethodErrors"
									:excluded-payment-method="paymentMethod"
									:payment-method-conditions="paymentMethodConditions"
									:selected-payment-method="newPaymentInfo"
									@changePaymentMethodSelected="changePaymentMethodSelected"
									@readyState="isPaymentInfoReady = $event" />
							</flow-info-section>
						</flow-info-group>
					</div>
					<div class="col-12">
						<div class="row mt-4 pl-4">
							<div class="col">
								<h5>{{ translate('this_orders_items') }}</h5>
							</div>
						</div>
						<div class="row mt-1">
							<div class="col">
								<cart-items
									:loading="systemLoading"
									:cart-items="orderProducts"
									:qty-import="qty"
									hide-loading
									editing-disabled />
							</div>
						</div>
						<is-loading
							:loading-label="translate('loading')"
							:no-data-label="translate('empty_cart')"
							:loading="systemLoading"
							:has-data="orderProducts.length > 0" />
						<hr class="my-3 w-100">
					</div>
				</div>
			</div>
			<div
				:class="{ 'mt-3': ['xs', 'sm'].includes(windowWidth) }"
				class="col-12 col-md-4">
				<order-summary
					:disable-action="systemLoading || !isPaymentInfoReady || (typeof paymentMethodConditions[clickedPayment] !== 'undefined' ? !paymentMethodConditions[clickedPayment].isAvailable : false)"
					:order-loading="loadingInitialInformation"
					:order-totals="orderTotals"
					:order-small-text-totals="orderSmallTextTotals"
					:order-items="orderProducts"
					:order-total="orderTotal"
					:total-volume="totalVolume"
					:exchange="exchange.is_needed ? exchange.total : ''"
					:style="['xs', 'sm'].includes(windowWidth) ? '' : `top: 85px !important;`"
					:class="['xs', 'sm'].includes(windowWidth) ? '' : 'sticky-top'"
					@payClicked="handlePayClicked" />
			</div>
		</div>
		<b-alert
			v-if="hasValidationErrors && ['xs','sm'].includes(windowWidth)"
			class="mt-2"
			show
			variant="danger">
			<h5 class="font-weight-bold">
				<i class="fa fa-exclamation-triangle" /> {{ translate('verify_following_errors') }}:
			</h5>
			<ul class="h6">
				<template v-for="(error, index) in validationErrors">
					<li :key="index">
						{{ error }}
					</li>
				</template>
			</ul>
		</b-alert>
	</div>
</template>
<script>
import CartItems from '@/components/Cart/CartItems';
import FlowInfoGroup from '@/components/FlowInfo/Group';
import FlowInfoSection from '@/components/FlowInfo/Section';
import IsLoading from '@/components/Loading';
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import { distributor } from '@/settings/Roles';
import { WALLET_TYPES } from '@/settings/Wallets';
import {
	Cart, Grids, Profile, Purchase as PurchaseMessages, Validations,
} from '@/translations';
import OrderSummary from './components/Confirmation/OrderSummary';
import PaymentEdit from './components/Payment/Edit';
import OrderData from './mixins/OrderData';

export default {
	name: 'ChangePaymentMethodConfirmation',
	messages: [Cart, Grids, Profile, PurchaseMessages, Validations],
	components: {
		CartItems,
		FlowInfoGroup,
		FlowInfoSection,
		IsLoading,
		OrderSummary,
		PaymentEdit,
	},
	mixins: [DocumentTitle, OrderData, WindowSizes],
	data() {
		return {
			distributor,
			canLeave: false,
			newPaymentInfo: {},
			validationErrors: [],
			isPaymentInfoReady: false,
			clickedPayment: '',
		};
	},
	computed: {
		hasValidationErrors() {
			return !!this.validationErrors.length;
		},
		hasShippingInfo() {
			try {
				return Object.keys(this.orderData.shipping_address).length > 0;
			} catch (e) {
				return false;
			}
		},
	},
	created() {
		this.initializeNewPaymentInfo();
	},
	async mounted() {
		this.getOrderInfo();
	},
	methods: {
		initializeNewPaymentInfo() {
			this.$set(this.newPaymentInfo, 'billing', {});
			this.$set(this.newPaymentInfo.billing, 'address_id', 0);

			this.$set(this.newPaymentInfo, 'payment_method', {});
			this.$set(this.newPaymentInfo.payment_method, 'name', '');
			this.$set(this.newPaymentInfo.payment_method, 'card_id', 0);
			this.$set(this.newPaymentInfo.payment_method, 'wallet_password', '');
			this.$set(this.newPaymentInfo.payment_method, 'wallet_type', WALLET_TYPES.commission);
			// eslint-disable-next-line no-underscore-dangle
			this.$set(this.newPaymentInfo.payment_method, 'sift_session_id', window._sift_session_id);
		},
		handlePayClicked() {
			this.validationErrors = [];

			const trans = {
				title: this.translate('important').toUpperCase(),
				text: this.translate('payment_method_change_notice'),
			};
			const options = {
				confirmButtonText: this.translate('pay'),
				cancelButtonText: this.translate('cancel'),
			};
			this.alert.confirmation(trans.title, trans.text, options).then(() => {
				this.changePaymentMethod(this.newPaymentInfo, {
					serverErrorHandler: this.goBack,
					invalidRequestHandler: this.goBack,
					validationErrorsHandler: this.handleValidationErrors,
				}).then(() => {
					this.$user.init();
				});
			}).catch(() => {});
		},
		goBack() {
			this.canLeave = true;
			this.$router.go(-1);
		},
		handleValidationErrors(errors) {
			this.validationErrors = errors;
		},
		changePaymentMethodSelected(selected) {
			if (selected !== 0) this.clickedPayment = selected;
		},
	},
	beforeRouteLeave(to, from, next) {
		if (to.name.includes('PSChangePaymentMethod')) {
			next();
			const alert = new this.$Alert();
			alert.close();
		} else if (['Login', 'PSStore', 'OrdersPickup', 'OrdersDetails'].includes(to.name)) {
			if (this.canLeave) {
				next();
				const alert = new this.$Alert();
				alert.close();
			} else {
				next();
			}
		}
	},
};
</script>
