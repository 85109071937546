import {
	GET_ORDER_INFO,
	PICKUP_CHANGE_ORDER_PAYMENT_METHOD,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from '../AxiosRequest';

class Order {
	constructor() {
		this.data = new Req(apiFilters);
		this.customDataObject = {};
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getInfo(orderId) {
		const { method, endpoint } = GET_ORDER_INFO;
		return this.data[method](endpoint(orderId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	changePaymentMethod(orderId, payload) {
		const { method, endpoint } = PICKUP_CHANGE_ORDER_PAYMENT_METHOD;
		return this.data[method](endpoint(orderId), payload);
	}
}

export default Order;
