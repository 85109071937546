import {
	PICKUP_CREATE_CART, PICKUP_ADD_CART_PRODUCTS, ADD_CART_COUPON, REMOVE_CART_COUPON, REMOVE_CART, PICKUP_GET_CART,
	PICKUP_GET_CART_PAYMENT_METHOD, USE_POINTS, REMOVE_POINTS, SPLIT_PAYMENT,
} from '@/config/endpoint';
import Req from '../AxiosRequest';

class Cart {
	constructor() {
		this.data = new Req();
		this.errors = this.data.errors;
	}

	clear() {
		this.data.clear();
	}

	getCart(cartId, options) {
		const { method, endpoint } = PICKUP_GET_CART;
		let isRegister = 0;
		if (options && options.is_register) isRegister = options.is_register;
		return this.data[method](endpoint(cartId), options, true, isRegister).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createCart() {
		const { method, endpoint } = PICKUP_CREATE_CART;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	addCartProducts(cartId, payload) {
		// refactor:
		// 	if the var name 'product' is necessary in payload
		// 	we need add it
		const { method, endpoint } = PICKUP_ADD_CART_PRODUCTS;
		return this.data[method](endpoint(cartId), payload).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	removeCart(cartId) {
		const { method, endpoint } = REMOVE_CART;
		return this.data[method](endpoint(cartId)).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	setCartCoupon(cartId, payload) {
		const { method, endpoint } = ADD_CART_COUPON;
		return this.data[method](endpoint(cartId), payload);
	}

	removeCartCoupon(cartId, payload) {
		const { method, endpoint } = REMOVE_CART_COUPON;
		return this.data[method](endpoint(cartId), payload).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors = error;
			return Promise.reject(error);
		});
	}

	getPaymentMethods(options) {
		const { method, endpoint } = PICKUP_GET_CART_PAYMENT_METHOD;
		return this.data[method](endpoint, options, true).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	usePoints(cartId) {
		const { method, endpoint } = USE_POINTS;
		return this.data[method](endpoint(cartId)).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	removePoints(cartId, options) {
		const { method, endpoint } = REMOVE_POINTS;
		return this.data[method](endpoint(cartId), options).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	splitPayment(cartId, payload) {
		const { method, endpoint } = SPLIT_PAYMENT;
		return this.data[method](endpoint(cartId), payload).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Cart;
