import {
	PICKUP_GET_ADDRESSES,
	PICKUP_GET_ADDRESS,
	PICKUP_SAVE_ADDRESS,
	ADDRESS_SUGGESTION,
} from '@/config/endpoint';
import Req from '../AxiosRequest';

class AddressBook {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getAddresses(userID) {
		const { method, endpoint } = PICKUP_GET_ADDRESSES;
		return this.data[method](endpoint(userID)).then((response) => response).catch((error) => {
			this.errors.record(error); return Promise.reject(error);
		});
	}

	getAddress(userID, addressID) {
		const { method, endpoint } = PICKUP_GET_ADDRESS;
		return this.data[method](endpoint(userID, addressID)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSuggestion(payload) {
		const { method, endpoint } = ADDRESS_SUGGESTION;

		this.options.data = payload;
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	saveAddress(userID, payload) {
		const { method, endpoint } = PICKUP_SAVE_ADDRESS;
		return this.data[method](endpoint(userID), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default AddressBook;
