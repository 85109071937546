import {
	GET_CREDIT_CARDS, GET_CREDIT_CARD, PICKUP_CREATE_CREDIT_CARD,
} from '@/config/endpoint';
import Req from '../AxiosRequest';

class CreditCard {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
	}

	clear() {
		this.data.clear();
	}

	getCreditCards(userID) {
		const { method, endpoint } = GET_CREDIT_CARDS;
		return this.data[method](endpoint(userID)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getCreditCard(userID, cardID) {
		const { method, endpoint } = GET_CREDIT_CARD;
		return this.data[method](endpoint(userID, cardID)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createCreditCard(userID, payload) {
		const { method, endpoint } = PICKUP_CREATE_CREDIT_CARD;
		return this.data[method](endpoint(userID), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default CreditCard;
