<!-- eslint-disable vue/no-v-html -->
<template>
	<cart-totals
		class="outline"
		:loading="orderLoading"
		:totals="orderTotals"
		:small-text-totals="orderSmallTextTotals"
		:cart-items="orderItems"
		:total="orderTotal"
		:total-volume="totalVolume"
		:disable-action="disableAction"
		:exchange="exchange"
		:action-text="translate('pay')"
		action-classes="btn-lg"
		@action="$emit('payClicked')" />
</template>
<script>
import CartTotals from '@/components/Cart/CartTotals';
import { Purchase } from '@/translations';

export default {
	name: 'OrderSummary',
	messages: [Purchase],
	components: {
		CartTotals,
	},
	props: {
		orderLoading: {
			type: Boolean,
			default: false,
		},
		orderItems: {
			type: Array,
			default: () => [],
		},
		orderTotals: {
			type: Object,
			default: () => ({}),
		},
		orderSmallTextTotals: {
			type: Array,
			default: () => [],
		},
		orderTotal: {
			type: String,
			default: '',
		},
		totalVolume: {
			type: String,
			default: '',
		},
		exchange: {
			type: String,
			default: '',
		},
		disableAction: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
<style scoped>
.outline {
	outline: 0;
	border-radius: 0.3rem;
	-webkit-box-shadow: 0 0 0 0.2rem #eb772f40;
	box-shadow: 0 0 0 0.2rem #eb772f40; /* eb772f40 */
}
</style>
