import {
	BAD_REQUEST, FORBIDDEN, NOT_FOUND, UNPROCESSABLE, INTERNAL_SERVER_ERROR,
} from '@/settings/Errors';
import Order from '@/util/PhysicalStore/Order';
import { PAYMENT_METHOD_LIMITS } from '@/settings/Purchase';

export default {
	data() {
		return {
			alert: new this.$Alert(),
			orderUtil: new Order(),
			changePaymentMethodUtil: new Order(),
			idempotencyId: '',
			validationErrors: [],
		};
	},
	computed: {
		orderErrors() {
			return this.orderUtil.data.errors;
		},
		changePaymentMethodErrors() {
			try {
				return this.changePaymentMethodUtil.data.errors;
			} catch (error) {
				return [];
			}
		},
		cartPagination() {
			return this.orderUtil.data.pagination;
		},
		orderProducts() {
			try {
				return this.orderData.order_totals.products;
			} catch (error) {
				return [];
			}
		},
		qty() {
			try {
				return this.orderData.order_totals.products.reduce((qtys, item) => ({ ...qtys, [item.item_id]: item.qty }), {});
			} catch (error) {
				return {};
			}
		},
		orderTotals() {
			try {
				return this.orderData.order_totals.totals;
			} catch (error) {
				return {};
			}
		},
		orderSmallTextTotals() {
			try {
				return this.orderData.order_totals.small_subtotals;
			} catch (error) {
				return [];
			}
		},
		orderNumericTotals() {
			try {
				return this.orderData.order_totals.numeric_totals;
			} catch (error) {
				return {};
			}
		},
		numericTotalPurchased() {
			try {
				return (this.orderNumericTotals.subtotal || 0) + (this.orderNumericTotals.discount || 0);
			} catch (error) {
				return 0;
			}
		},
		totalVolume() {
			try {
				return this.orderData.total_volume.toString();
			} catch (error) {
				return '';
			}
		},
		orderTotal() {
			try {
				return this.orderData.order_totals.total;
			} catch (error) {
				return '';
			}
		},
		shippingMethod() {
			try {
				return this.orderData.shipping_method;
			} catch (error) {
				return '';
			}
		},
		orderData() {
			try {
				return this.orderUtil.data.response.data.response;
			} catch (error) {
				return {};
			}
		},
		orderHasData() {
			const response = Object.keys(this.orderData).length;
			return !!response;
		},
		paymentMethod() {
			return this.orderData.payment_method;
		},
		exchange() {
			try {
				const { exchange } = this.orderData.attributes;
				return exchange;
			} catch (error) {
				return [];
			}
		},
		productsHasData() {
			if (this.orderHasData) {
				const response = this.orderData.order_totals.products.length;
				return !!response;
			}
			return false;
		},
		totalsHasData() {
			if (this.orderHasData) {
				const response = Object.keys(this.orderData.order_totals.totals).length;
				return !!response;
			}
			return false;
		},
		loadingChangePaymentMethod() {
			return this.changePaymentMethodUtil.data.loading;
		},
		loadingInitialInformation() {
			return this.orderUtil.data.loading;
		},
		systemLoading() {
			const ops = [
				this.orderUtil,
				this.changePaymentMethodUtil,
			];

			return ops.reduce((loading, op) => (!!op.data.loading || loading), false);
		},
		orderId() {
			return this.$route.params.order_id;
		},
		paymentMethodConditions() {
			const paymentMethodLimits = { ...PAYMENT_METHOD_LIMITS.defaultCountry, ...PAYMENT_METHOD_LIMITS[this.country] };
			return Object.fromEntries(Object.entries({ ...paymentMethodLimits.defaultFlow, ...paymentMethodLimits.purchase }).map((paymentMethod) => {
				const [codeName, limits] = paymentMethod;
				const subtotalMin = typeof limits.min !== 'undefined' ? this.numericTotalPurchased >= limits.min : true;
				const subtotalMax = typeof limits.max !== 'undefined' ? this.numericTotalPurchased <= limits.max : true;
				const isSubtotalWithinLimits = subtotalMin && subtotalMax;
				return [
					codeName,
					{
						min: limits.min,
						max: limits.max,
						isAvailable: isSubtotalWithinLimits,
					},
				];
			}));
		},
	},
	watch: {
		orderId() {
			this.getOrderInfo();
		},
	},
	mounted() {
		this.orderUtil.getInfo(this.orderId);
		this.idempotencyId = this.createUuid();
	},
	methods: {
		getOrderInfo() {
			this.orderUtil.getInfo(this.orderId).catch((getInfoError) => {
				if (![...NOT_FOUND, ...FORBIDDEN, ...BAD_REQUEST].includes(getInfoError.status)) {
					this.alert.toast('error', this.translate('default_error_message'));
				}
			});
		},
		changePaymentMethod(payload, {
			serverErrorHandler,
			invalidRequestHandler,
			validationErrorsHandler,
		}) {
			payload.payment_method.idempotency_id = this.idempotencyId;

			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('processing_request'), this.translate('white_util_checkout'), options);
			try {
				return this.changePaymentMethodUtil.changePaymentMethod(this.orderId, { payment: payload }).then((response) => {
					// Reload user data in case this purchase reactivated their account
					this.$user.init();

					const {
						redirect_url: redirectUrl,
						verification_required: verificationRequired,
						crypto_wallet: cryptoWallet,
						qr_code: qrCode,
					} = response.response;
					// Redirect to instructed page
					if (redirectUrl) {
						window.location.href = redirectUrl;
						return;
					}
					// Pass payment method to success page in case we need to display an alert for it
					const routeOptions = {
						name: 'PSChangePaymentMethodSuccess',
						params: { order_id: this.orderId, qrCode },
						query: { payment_method: payload.payment_method.name ?? '' },
					};
					// Show verbal verification alert
					if (verificationRequired) {
						routeOptions.query.verification_required = true;
					}
					// Go to crypto payment details
					if (cryptoWallet) {
						routeOptions.query = { wallet_address: cryptoWallet };
					}
					// Go to new route
					this.$router.replace(routeOptions);
					// Close 'Processing request...' alert
					this.alert.close();
				}).catch((error) => {
					this.idempotencyId = this.createUuid();
					if ([...NOT_FOUND, ...FORBIDDEN].includes(error.status)) {
						invalidRequestHandler(error);
					}
					if (UNPROCESSABLE.includes(error.status)) {
						let isCatchableError = false;
						const {
							payex,
							'payment.billing.address_id': billingAddressId,
							'payment.payment_method.name': paymentMethodName,
							'payment.payment_method.wallet_password': walletPassword,
							'payment.payment_method.wallet_type': walletType,
							'payment.payment_method.card_id': cardId,
						} = error.errors;
						if (typeof payex !== 'undefined') {
							isCatchableError = true;
							let response = '';
							payex.forEach((item) => { response += `${item} <br>`; });
							this.alert.error(this.translate('something_went_wrong'), response, true);
						}
						if (typeof paymentMethodName !== 'undefined') {
							isCatchableError = true;
							let response = '';
							paymentMethodName.forEach((item) => { response += `${item} \n`; });
							this.alert.toast('error', response, { timer: 6000 });
						}
						const errors = [];
						if (typeof billingAddressId !== 'undefined') {
							billingAddressId.forEach((item) => { errors.push(item); });
						}
						if (typeof walletPassword !== 'undefined') {
							walletPassword.forEach((item) => { errors.push(item); });
						}
						if (typeof walletType !== 'undefined') {
							walletType.forEach((item) => { errors.push(item); });
						}
						if (typeof cardId !== 'undefined') {
							cardId.forEach((item) => { errors.push(item); });
						}
						validationErrorsHandler(errors);

						if (error.length) {
							isCatchableError = true;
							this.alert.close();
						}
						if (isCatchableError === false) {
							this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
						}
					} else if (INTERNAL_SERVER_ERROR.includes(error.status)) {
						this.alert.toast('error', this.translate('something_went_wrong'), { timer: 2500 });
						setTimeout(() => { serverErrorHandler(); }, 2500);
					}
				});
			} catch (error) {
				if (process.env.NODE_ENV !== 'production') {
					console.warn(error);
				}
			}
			return null;
		},
	},
};
